<template>
  <div v-if="userHandle">
    <div v-if="signedIn" class="dropdown dropdown-end drop-shadow-md">
      <div
        tabindex="0"
        role="button"
        class="flex flex-nowrap items-center btn btn-primary btn-outline ring-2 btn-sm m-1">
        <MaxLabel :value="userHandle" />
        <PhCaretDown />
      </div>
      <ul
        id="id_dropdown"
        tabindex="0"
        class="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-lg w-52 mt-1 ring-2">
        <li>
          <a @click="gotoUserProfile">
            <ph-person />
            {{ sessionStore.i18nContent.userProfile }}</a
          >
        </li>
        <hr class="my-1.5" />
        <li>
          <a @click="gotoSignOut">
            <ph-sign-out />
            {{ sessionStore.i18nContent.signOut }}</a
          >
        </li>
      </ul>
    </div>
    <div v-else-if="sessionStore.isEventActive()">
      <NuxtLink
        :to="localeRoute('booths-eventId-guest-signin')"
        class="btn btn-primary btn-outline ring-2 btn-sm"
        >{{ sessionStore.i18nContent.signIn }}</NuxtLink
      >
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useSessionStore } from "@/stores/session";
  import { ref } from "vue";
  import { PhCaretDown, PhPerson, PhSignOut } from "@phosphor-icons/vue";
  import { onAuthStateChanged } from "firebase/auth";

  const sessionStore = useSessionStore();
  const signedIn = ref(sessionStore.signedIn());
  const userHandle = ref(sessionStore.auth?.currentUser?.displayName!);
  const localeRoute = useLocaleRoute();

  onAuthStateChanged(sessionStore.auth!, () => {
    signedIn.value = sessionStore.signedIn();
    userHandle.value = sessionStore.auth?.currentUser?.displayName!;
  });

  sessionStore.$onAction(({ name }) => {
    if (name === "setLoading") {
      userHandle.value = sessionStore.auth?.currentUser?.displayName!;
    }
  });

  const gotoUserProfile = () => {
    navigateTo(
      localeRoute({
        name: "booths-eventId-guest-profile",
        query: useRoute().query,
      })
    );
    document.getElementById("id_dropdown")?.blur();
  };

  const gotoSignOut = async () => {
    await sessionStore.signOut();
    navigateTo(
      localeRoute({ name: "booths-eventId", query: useRoute().query })
    );

    document.getElementById("id_dropdown")?.blur();
  };
</script>

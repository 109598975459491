<template>
  <AppBackgroundDecoration />
  <div
    class="grow flex flex-col"
    :class="{ 'bg-neutral/80': !withoutMockedPhone }">
    <div
      v-if="!isGuest && !isMocked"
      class="sticky top-0 w-full py-1 px-4 bg-warning text-center z-20">
      You are previewing the Instabooth app for guests. When you are done click
      <NuxtLink
        :to="localeRoute({ name: 'events-eventId' })"
        class="kbd kbd-sm px-2 bg-opacity-70"
        >here</NuxtLink
      >
      to go back to Instabooth management.
    </div>

    <div class="relative grow flex flex-col" v-if="withoutMockedPhone">
      <div
        v-if="isMocked"
        class="fixed h-8 z-20 w-full top-0 bg-base-200 px-8 flex justify-between items-center font-bold">
        <div class="text-xs">TELCOM</div>
        <div class="flex gap-1">
          <PhCellSignalFull weight="bold" />
          <div class="text-xs">5G</div>
          <PhBatteryHigh weight="bold" />
        </div>
      </div>
      <BoothHeader :hide-header="hideHeader" />
      <main
        class="flex-1 flex flex-col container mx-auto p-6"
        :class="{ 'pt-8 overflow-x-hidden': isMocked }">
        <slot />
      </main>
      <BoothFooter v-if="!hideHeader" />
    </div>
    <div v-else class="flex-1 mx-auto my-4">
      <div
        class="mockup-phone shadow-lg animate__animated animate__slideInLeft">
        <div class="camera"></div>
        <div class="display">
          <div
            class="artboard artboard-demo"
            :class="{
              'phone-2': height < 812,
              'phone-4': height >= 812,
              'phone-5': height >= 896,
            }">
            <iframe
              class="h-full w-full border-0"
              frameborder="none"
              :src="`${url.pathname}?mocked=true`">
            </iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PhBatteryHigh, PhCellSignalFull } from "@phosphor-icons/vue";
  import { useWindowSize } from "@vueuse/core";
  import { doc, setDoc } from "firebase/firestore";
  import type { Photographer } from "~/model";

  const sessionStore = useSessionStore();
  const localeRoute = useLocaleRoute();
  const isGuest = ref(true);
  const url = useRequestURL();
  const isMocked = ref(url.search.includes("mocked"));
  const { height, width } = useWindowSize();
  const isWide = computed(() => width.value > 500);
  const route = useRoute();
  const hideHeader = ref(false);
  const { locale } = useI18n();
  provide("hideHeader", hideHeader);

  onMounted(() => {
    isGuest.value =
      sessionStore.auth?.currentUser?.providerData[0]?.providerId != "password";

    if (sessionStore.isEventInTheFuture || sessionStore.isEventActive()) {
      useHead({
        link: [
          {
            href: `/booths/${route.params.eventId}/manifest.webmanifest`,
            rel: "manifest",
          },
        ],
      });
    }
  });

  const withoutMockedPhone = computed(
    () => isGuest.value || isMocked.value || !isWide.value
  );

  const countPhotographer = () => {
    if (!sessionStore.auth?.currentUser?.uid) return;
    const path = `/events/${route.params.eventId}/photographers/${sessionStore.auth?.currentUser?.uid}`;
    setDoc(
      doc(sessionStore.db!, path),
      <Photographer>{
        locale: locale.value,
      },
      {
        merge: true,
      }
    );
  };

  sessionStore.auth?.onAuthStateChanged(() => countPhotographer());
</script>

<style>
  body {
    padding-top: env(safe-area-inset-top);
  }
</style>

<template>
  <footer
    class="flex-none flex flex-col items-center md:flex-row md:justify-center bg-base-100/40 text-xs p-1 space-x-4 _placeholder">
    <span>
      All contents © {{ dayjs().format("YYYY") }} Instabooth Limited. All
      rights reserved.
    </span>
    <div class="space-x-4">
      <NuxtLink
        class="link"
        :to="
          localeRoute({
            name: 'booths-eventId-doc',
            params: { doc: 'tou' },
            query: $route.query,
          })
        "
        >{{ sessionStore.i18nContent.termsOfUse }}</NuxtLink
      >
      <NuxtLink
        class="link"
        :to="
          localeRoute({
            name: 'booths-eventId-doc',
            params: { doc: 'privacy' },
            query: $route.query,
          })
        "
        >{{ sessionStore.i18nContent.privacyPolicy }}</NuxtLink
      >
    </div>
  </footer>
</template>

<script setup lang="ts">
  import dayjs from "dayjs";
  const sessionStore = useSessionStore();
  const localeRoute = useLocaleRoute();
</script>
